import Seo from '../components/seo'
import FaqAccordion from '../components/FaqAccordion/FaqAccordion'
import faq from '../content/faq'
import MarkdownText from '../components/MarkdownText/MarkdownText'
import '../scss/pages/faq.scss'

const { meta_title, meta_description, meta_image } = faq

const FaqPage = () => {
  return (
    <section className="FAQSection">
      <h1 className="FAQTitle">{faq.title}</h1>
      <MarkdownText text={faq.sub_title} className="FAQDescription" />
      <FaqAccordion />
    </section>
  )
}

export default FaqPage

export const Head = () => {
  return (
    <Seo title={meta_title} description={meta_description} image={meta_image} />
  )
}
