import { Accordion } from 'component-library'
import faq from '../../content/faq'
import MarkdownText from '../MarkdownText/MarkdownText'

const { questions_answers } = faq

const FaqAccordion = () => {
  return (
    <section className="FaqAccordion">
      {questions_answers.map(
        (item: { title: string; description: string }, index) => (
          <Accordion
            key={`${item.title}-${index}`}
            itemTitle={item.title}
            itemContent={<MarkdownText text={item.description} />}
          />
        )
      )}
    </section>
  )
}

export default FaqAccordion
