export default {
  meta_title: 'Frequently Asked Questions (FAQs) | PennyLane',
  meta_description:
    'Get quick answers to your most burning questions on PennyLane and quantum machine learning.',
  meta_image: 'https://assets.cloud.pennylane.ai/pennylane_website/generic/thumbnail.png',
  title: 'Frequently Asked Questions',
  sub_title: 'Get **quick answers** to your most burning questions.',
  questions_answers: [
    {
      title: 'What is PennyLane?',
      description:
        'PennyLane is the **definitive open-source Python framework for quantum programming**, built by researchers, for research. It supports [quantum computing](/qml/quantum-computing/), [quantum machine learning](/qml/quantum-machine-learning/), and [quantum chemistry](/qml/quantum-chemistry/), enabling researchers to [build circuits](https://docs.pennylane.ai/en/stable/code/api/pennylane.QNode.html), [train models](/qml/demos/tutorial_qubit_rotation/), and [run on simulators or hardware](/performance/).',
    },
    {
      title: 'Does PennyLane work with hardware?',
      description:
        'Yes, PennyLane supports running quantum circuits on hardware devices. Simply select a hardware backend as your device and take advantage of advanced features like error mitigation. Explore available options in the [devices section](/plugins).',
    },
    {
      title: 'Can I use PennyLane with automatic differentiation libraries (NumPy, PyTorch, TensorFlow and JAX)?',
      description:
        'Yes, PennyLane integrates with NumPy, PyTorch, TensorFlow, and JAX. More information can be found in the [Gradients and training documentation](https://docs.pennylane.ai/en/stable/introduction/interfaces.html).',
    },
    {
      title: 'What distinguishes PennyLane from other quantum programming languages?',
      description:
        'While offering a lot of the functionality of standard quantum programming languages, PennyLane is built around the ideas of differentiable quantum programming—important in quantum machine learning and quantum chemistry—and being modular across the entire ecosystem, letting you interface PennyLane with your favourite hardware or simulator backends.',
    },
    {
      title: 'How do I get started with PennyLane?',
      description:
        'Start [here](https://docs.pennylane.ai/en/stable/) to explore PennyLane features. Dive into our [Demos](/qml/demonstrations/) for in-depth code examples and practical applications of state-of-the-art algorithms and techniques using PennyLane.',
    },
    {
      title: 'What is quantum computing?',
      description: `

Quantum computing is a research area that extends the set of physical laws classical computers operate on by accessing **quantum aspects of the physical world**, opening up new ways of processing information. Quantum computing sits at the meeting point of algorithm design, complexity theory, system architecture, and hardware development. Applications of quantum computing span from low-level hardware problems to higher-level and abstract problems, such as breaking classical encryption protocols, simulating quantum systems, or training quantum neural networks.

The primary ambition of quantum computing nowadays is to develop a scalable, fault-tolerant device to implement any and all quantum algorithms, despite current noisy, intermediate-scale quantum (NISQ) devices. Quantum computers are likely to be one of the cornerstone technologies of the 21st century, **changing the way we do research, protect our data, communicate, and understand the world around us**.

For example, quantum computers could speed up the development of [new chemical compounds](/qml/what-is-quantum-chemistry/), enable secure private message exchanges, enhance algorithms for tasks like image classification and traffic management, and more. Learn more about [quantum computing](/qml/quantum-computing/).`

    },
    {
      title: 'What is quantum machine learning?',
      description: `
Quantum machine learning investigates the **consequences of using quantum computers for machine learning**, by extending the pool of hardware for machine learning by an entirely new type of computing device—the quantum computer.

One can understand these devices as a form of special-purpose hardware like Application-Specific Integrated Circuits (ASICs) and Field-Programmable Gate Arrays (FPGAs), as they are limited in the number and type of operations that can be executed in a single run. However, information processing with quantum computers relies on substantially different laws of physics compared to ASICs and FPGAs.

In modern quantum machine learning, near-term quantum devices are used and trained like neural networks, using **variational quantum circuits**. Learn more about [quantum machine learning](/qml/quantum-machine-learning/).`
    },
    {
      title: 'What is quantum chemistry?',
      description: `

Quantum chemistry **calculates properties of molecules and the materials built out of them using quantum mechanics**. This field enables complex simulations and material modeling that go beyond classical methods, with quantum computers offering distinct advantages in these tasks.

Understanding the quantum properties of materials is of growing importance in a wide range of industries. As a result, quantum chemistry is viewed as the **leading candidate for a practical application of quantum computing**. Current quantum devices are small, and are therefore limited to efficiently simulating small systems. Hence, there is a push to develop **scalable algorithms** that will work on larger devices, which we expect to become available as current hardware limitations pass.

**Optimization**, essential in quantum chemistry, also connects to [quantum machine learning](/qml/quantum-machine-learning/). Algorithms like the [variational quantum eigensolver (VQE)](/qml/demos/tutorial_vqe/) have inspired key discoveries applicable to quantum chemistry. Learn more about the field of [quantum chemistry](/qml/quantum-chemistry/).`
      
    },
    {
      title: 'How can I stay updated on the latest PennyLane news?',
      description: `
Follow PennyLane on [X (formerly Twitter)](https://x.com/PennyLaneAI) and [LinkedIn](https://www.linkedin.com/company/pennylaneai/) to receive updates on the latest PennyLane releases, demos, blogs, learning material, partnerships, and much more. To stay in the know and get our monthly news straight to your inbox, sign up to receive the [Xanadu Newsletter](https://xanadu.us17.list-manage.com/subscribe?u=725f07a1d1a4337416c3129fd&id=294b062630).

For more on PennyLane releases, explore our latest [PennyLane release blogs](/search/?contentType=BLOG&categories=releases&sort=publication_date).`
    },
    {
      title: 'How can I get support for PennyLane?',
      description: `
For issues or bug reports, visit our [GitHub repository](https://github.com/PennyLaneAI/pennylane) and use the [issue tracker](https://github.com/PennyLaneAI/pennylane/issues) to let us know.

Join the [PennyLane Discussion Forum](https://discuss.pennylane.ai/) to connect with the quantum community, get support, and engage directly with our team. It’s the perfect place to share ideas, ask questions, and collaborate with fellow researchers and developers!`
    },
    {
      title: 'How can I share my research with the quantum computing community?',
      description: `Collaborate with us to highlight your innovations to fellow researchers, whether you're using PennyLane or another tool. Learn how to [contribute a demo or blog post](/get-involved/) or [quantum data](/datasets).`
    },
    {
      title: 'How does PennyLane evaluate gradients of quantum circuits?',
      description: `Wherever possible, **PennyLane uses parameter-shift rules** to extract gradients of quantum circuits. These rules prescribe how to estimate a gradient by running a circuit twice or more times with deliberately shifted parameters.

In situations where no parameter-shift rule can be applied, PennyLane uses the finite-difference rule to approximate a gradient.

Both options work whether you run your code on simulators or an actual quantum device.`
    },
    {
      title: 'How can I contribute to PennyLane?',
      description: `
        
We welcome contributions—simply fork the PennyLane repository, and then make a [pull request](https://help.github.com/articles/about-pull-requests/) containing your contribution. All contributors to PennyLane will be listed as authors on the releases. All users who contribute significantly to the code (new plugins, new functionality, etc.) will be listed on the [PennyLane arXiv paper](https://arxiv.org/abs/1811.04968).

We also encourage bug reports, suggestions for new features and enhancements, and even links to projects or applications built on PennyLane.

See our [contributions page](https://github.com/PennyLaneAI/pennylane/blob/master/.github/CONTRIBUTING.md) and our [Development guide](https://docs.pennylane.ai/en/stable/development/guide.html) for more details.`
    },
    {
      title: 'Is PennyLane open source?',
      description:
        'Yes, PennyLane is open source software developed under the Apache 2.0 License.',
    },
  ],
}
